import React, { useState } from 'react';

import './App.css';
import logo from './images/logo.png';

import heroImage from './images/hero.png';

import turtleConfigImage from './images/turtle_configuration.png';
import turtleActionImage from './images/turtle_in_action.png';

function InstallButton() {
    return (<a className='inline-block text-lg text-white
    font-bold bg-turtle-green hover:bg-turtle-green-dark rounded-full py-2 px-4
    drop-shadow my-6 uppercase' href="https://chrome.google.com/webstore/detail/focus-turtle/dbehoihdibjffmakffjllgijeemhchmh?hl=en&authuser=0">Install extension - it's free!</a>
    );
}


function App() {
    const [open, toggleOpen] = useState(true);

    return (
        <>
            <nav className='xl:w-4/6 m-auto header flex items-center justify-start p-4 text-2xl'>
                <img src={logo as string} alt="logo - turtle" width="30px" className='rounded-full mr-2' /> Focus Turtle
            </nav>

            <main className='p-4 xl:w-4/6 m-auto mb-8'>
                <div className='my-4 flex gap-4 leading-6 flex-col md:flex-row'>
                    <div className="md:w-1/2">
                        <h1 className='text-5xl my-4 md:mb-6'>Avoid distractions and get stuff done</h1>
                        <p className='my-4'>
                            Cute extension to remind you about what is your real focus.
                        </p>
                        <p>
                            Internet is full of distraction traps, but necessary for us to do our work, Focus Turtle will help you set the intention and
                            avoid getting hijacked by the internet distractions.
                        </p>
                        <InstallButton />
                    </div>
                    <div className='md:w-1/2'>
                        <img src={heroImage} alt="person with a laptop with app open" className='rounded drop-shadow-md mr-2 w-max' />

                    </div>

                </div>

                <div className="testimonials-list flex flex-col lg:flex-row gap-10 items-center mt-12 mb-12">

                    <div className="testimonial basis-1/3">

                        <div className="testimonial-body text-center text-2xl">
                            "I'm just a turtle, but even I have problems focusing at times! <span className="bg-orange-200">Focus Turtle is a real help when I get distracted!</span>"
                        </div>
                        <a href="" className="testimonial-author text-center mt-5 flex flex-row justify-center items-center gap-2 font-bold">
                            <img width="100px" src={logo} className="rounded-full" />Turtle Arthur</a>

                    </div>

                    <div className="testimonial basis-1/3">

                        <div className="testimonial-body text-center text-2xl">
                            "<span className="bg-orange-200">I really like how supportive Focus Turtle is</span>,
                            I never feel judged when it tells me to get back to work."
                        </div>
                        <a href="" className="testimonial-author text-center mt-5 flex flex-row justify-center items-center gap-2 font-bold">
                            <img width="100px" src={logo} className="rounded-full" /> Turtle Betty</a>

                    </div>

                    <div className="testimonial basis-1/3 ">

                        <div className="testimonial-body text-center text-2xl">
                            "It's not like other focus timer, it's a cute turtle! <span className="bg-orange-200">I like that every sessions starts with setting the intention.</span>
                            Would recommend!"
                        </div>
                        <a href="" className="testimonial-author text-center mt-5 flex flex-row justify-center items-center gap-2 font-bold">
                            <img width="100px" src={logo} className="rounded-full" />Turtle Charlie</a>

                    </div>

                </div>

                <p className='text-center'>If you want to help out, <a target="_blank" href="https://chrome.google.com/webstore/detail/focus-turtle/dbehoihdibjffmakffjllgijeemhchmh?hl=en&authuser=0" className='underline'>submit a testimonial!</a> Thanks!</p>
                <h2 className='text-5xl my-[100px] text-center'>Why Focus Turtle?</h2>

                <div className='my-24 flex gap-8 leading-6 flex-col md:flex-row'>
                    <div className="md:w-1/2">
                        <h1 className='text-3xl my-4 md:mb-6'>It’s a turtle - you can’t say no to it</h1>
                        <p className='my-4'>
                            A typical timer is not a cute turtle, it also doesn’t tell you what was your intention.
                        </p>
                        <p>
                            Focus Turtle is different, you can’t say no to a cute turtle, that would be cruel!
                        </p>
                        <InstallButton /></div>
                    <div className='md:w-1/2'>
                        <img src={logo} alt="person with a laptop with app open" className='rounded drop-shadow-md mr-2 w-max' />

                    </div>

                </div>

                <div className='my-24 flex gap-8 leading-6 flex-col md:flex-row'>
                    <div className='md:w-1/2'>
                        <img src={turtleActionImage} alt="person with a laptop with app open" className='rounded drop-shadow-md mr-2 w-max' />

                    </div>

                    <div className="md:w-1/2">
                        <h1 className='text-3xl my-4 md:mb-6'>It helps you to return to focus</h1>
                        <p className='my-4'>
                            The problem with many focus apps is that they don’t bring you back to focus. If you get distracted, that’s it. Instead of making progress on your goals you are looking at cat videos on reddit!
                        </p>
                        <p>
                            For the Focus Turtle bringing you back to focus and “checking in on” you is the first class feature. It’s like having an accountability buddy, but your buddy is a turtle.
                        </p>
                        <InstallButton /> </div>

                </div>

                <div className='my-24 flex gap-8 leading-6 flex-col md:flex-row'>
                    <div className="md:w-1/2">
                        <h1 className='text-3xl my-4 md:mb-6'>It makes productivity more fun</h1>
                        <p className='my-4'>
                            Does being productive require being serious? No! It doesn’t have to be all tense and serious.
                        </p>
                        <p>
                            You can be productive and have fun. Focus Turtle will provide regular check ins, will always be positive and supportive. And sometimes even proud!
                        </p>
                        <InstallButton /></div>
                    <div className='md:w-1/2'>
                        <img src={turtleConfigImage} alt="person with a laptop with app open" className='rounded drop-shadow-md mr-2 w-max' />

                    </div>

                </div>

                <h2 className='text-5xl my-[100px] text-center'>FAQ</h2>


                <div className="flex flex-col gap-10 items-left mt-12 mb-12">

                    <div className="">

                        <div className="text-2xl my-2">
                            What if I don’t want to be productive?</div>

                        <div>
                            Then don’t activate the turtle. You can still have it installed. You can even activate it to focus on “fun” like watching a series without getting distracted from that…
                        </div>

                    </div>

                    <div className="">

                        <div className="text-2xl my-2">
                            This extension doesn’t look very popular… is it a good idea to install it?</div>

                        <div>
                            Oh, this is because it’s so new! But that also means that as a new user you can have much bigger impact on its development.</div>

                    </div>

                    <div className="">

                        <div className="text-2xl my-2">
                            How can I give feedback and request new features?</div>

                        <div>
                            Use this <a href="https://forms.gle/Nbknn5cVexogoVD38" className="underline" target="_blank">form</a> or reach out to <a href="https://twitter.com/attilczuk" className="underline" target="_blank">Justyna</a> directly.
                        </div>
                    </div>
                    <div className="">

                        <div className="text-2xl my-2">
                            How can I help out?</div>

                        <div>
                            Spread the word about the Focus Turtle! I’m also looking for <a target="_blank" href="https://chrome.google.com/webstore/detail/focus-turtle/dbehoihdibjffmakffjllgijeemhchmh?hl=en&authuser=0" className='underline'>testimonials</a>. Thanks!
                        </div>
                    </div>
                </div>

                <h2 className='text-5xl my-[100px] text-center'><InstallButton /></h2>
            </main>
            <div className={"fixed bottom-0 flex flex-row bg-[#f3f4f6] border-t py-2 md:py-4 w-full px-2 gap-2 z-50 " + (open ? "" : "hidden")}>
                <a href="https://watchlimits.com/?ref=netflixcalculator"
                    className=" flex-1 flex flex-row justify-start md:justify-center items-center gap-2 hover:opacity-70 duration-200">
                    <div className="flex flex-col lg:items-center">
                        <div className="font-semibold">Want more? Try <span
                            className=" text-red-600 underline whitespace-nowrap">Watchlimits</span>
                        </div>
                        <div className="text-sm">Chrome Extension that will help you avoid getting lost for hours watching video content!</div>
                    </div>
                </a>
                <button className="flex-none btn btn-square btn-ghost" aria-label="Close footer" onClick={() => toggleOpen(false)}>

                    <span className="material-symbols-outlined">close</span>
                </button>
            </div>
        </>

    );
}

export default App;
